var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","persistent":_vm.load,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.load}},[_c('v-card-title',{staticStyle:{"word-break":"normal"}},[_vm._v(" Cerrar Caja "),(_vm.local_nombre != '')?_c('div',{staticClass:"body-1 mt-1 ml-sm-3 ml-0"},[_vm._v(" "+_vm._s(_vm.local_nombre)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-6",class:_vm.ajuste ? 'pb-3' : 'pb-5'},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field-money',{attrs:{"label":"Efectivo","oncopy":"return false","oncut":"return false","properties":{
              prefix: '$',
              type: 'tel',
              'hide-details': true,
              outlined: true,
              readonly: true,
              filled: true,
              dense: true
            },"options":{
              locale: 'es-AR',
              precision: 2,
              empty: null
            }},model:{value:(_vm.efectivo),callback:function ($$v) {_vm.efectivo=$$v},expression:"efectivo"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('text-field-money',{attrs:{"label":"Efectivo arqueo","onpaste":"return false","properties":{
              prefix: '$',
              'hide-details': true
            }},model:{value:(_vm.arqueo),callback:function ($$v) {_vm.arqueo=$$v},expression:"arqueo"}})],1),(_vm.ajuste)?_c('v-col',{staticClass:"d-flex justify-sm-start justify-end align-center py-0 error--text body-1 font-weight-bold",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm.faltante ? 'Faltante' : 'Sobrante')+" ")]):_vm._e(),(_vm.ajuste)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"obsAjuste"},[_c('v-textarea',{attrs:{"label":"Motivo ajuste","rows":"2","counter":50,"rules":[_vm.rules.required, _vm.rules.max],"validate-on-blur":"","auto-grow":"","outlined":"","dense":""},model:{value:(_vm.observacion),callback:function ($$v) {_vm.observacion=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"observacion"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pb-4",class:_vm.ajuste ? 'pt-0' : 'pt-2'},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","disabled":_vm.arqueo == null,"loading":_vm.load},on:{"click":function($event){return _vm.controlCerrarCaja()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-cash-register")]),_vm._v(" Cerrar Caja ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }