var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500","persistent":_vm.load},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.load}},[_c('v-card-title',{staticStyle:{"word-break":"normal"}},[_vm._v(" Agregar Empresa "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-8"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"4"}},[_c('v-text-field-integer',{attrs:{"label":"CUIT","properties":{
                hint: 'xx-xxxxxxxx-x',
                rules: [_vm.rules.required],
                'validate-on-blur': true,
                'persistent-hint': true,
                autofocus: true,
                outlined: true,
                dense: true
              },"options":{
                inputMask: '##-########-#',
                outputMask: '##-########-#',
                empty: null
              }},model:{value:(_vm.cuit),callback:function ($$v) {_vm.cuit=$$v},expression:"cuit"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"8"}},[_c('v-text-field',{attrs:{"label":"Razón social","rules":[_vm.rules.required],"validate-on-blur":"","outlined":"","dense":""},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pb-4"},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('BtnConfirmar',{attrs:{"clase":"ml-2","loading":_vm.load},on:{"action":function($event){return _vm.guardar()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }