<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title style="word-break: normal;">
        Agregar Empresa
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-8">
        <v-form ref="form">
          <v-row>
            <v-col cols="6" sm="4" class="py-0">
              <v-text-field-integer
                v-model="cuit"
                label="CUIT"
                v-bind:properties="{
                  hint: 'xx-xxxxxxxx-x',
                  rules: [rules.required],
                  'validate-on-blur': true,
                  'persistent-hint': true,
                  autofocus: true,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  inputMask: '##-########-#',
                  outputMask: '##-########-#',
                  empty: null
                }"
              />
            </v-col>
            <v-col cols="12" sm="8" class="py-0">
              <v-text-field
                v-model.trim="nombre"
                label="Razón social"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          clase="ml-2"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      cuit: null,
      nombre: null,
      rules: {
        required: value => !!value || 'Campo requerido'
      }
    }
  },
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    dialog (val) {
      val || this.limpiar()
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        if (this.cuit.length != 13) {
          return this.$store.dispatch('show_snackbar', {
            text: 'Ingrese un CUIT válido con formato xx-xxxxxxxx-x',
            color: 'orange'
          })
        }
        this.load = true
        let respuesta = await this.$store.dispatch('caja/nueva_empresa', {
          cuit: this.cuit,
          nombre: this.nombre
        })
        this.load = false

        if (respuesta.exito == 1) {
          this.$emit('nueva', this.cuit)
          this.dialog = false
          this.$store.dispatch('show_snackbar', {
            text: 'Empresa creada correctamente',
            color: 'success'
          })
        } else {
          this.$store.dispatch('show_snackbar', {
            text: respuesta.message,
            color: 'error'
          })
        }
      }
    },
    limpiar () {
      this.cuit = null
      this.nombre = null
      this.$refs.form.resetValidation()
    }
  }
}
</script>