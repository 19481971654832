<template>
  <v-expand-transition>
    <div
      v-show="seleccion.length > 0"
      class="body-2 pt-2 mx-1"
    >
      {{ seleccion.length }} fila{{ seleccion.length > 1 ? 's' : '' }} seleccionada{{ seleccion.length > 1 ? 's' : '' }} de {{ todas.length }}
      <template v-if="!disabled">
      <span v-if="seleccion.length != todas.length">
        -
        <v-hover v-slot="{ hover }">
          <a
            :class="hover ? 'text-decoration-underline' : ''"
            @click="seleccionar()"
          >
            Seleccionar todas
          </a>
        </v-hover>
      </span>
      -
      <v-hover v-slot="{ hover }">
        <a
          class="orange--text"
          :class="hover ? 'text-decoration-underline' : ''"
          @click="seleccion = []"
        >
          Limpiar selección
        </a>
      </v-hover>
      </template>
    </div>
  </v-expand-transition>
</template>

<script>
/**
 *  Componente que muestra la cantidad de filas seleccionadas
 * 
 *  Nota: sirve cuando se usa un data table con selected paginado, ya que
 *        al seleccionar mediante el check del header solo selecciona las
 *        filas actuales que se ven en pantalla pero no el resto. De
 *        esta forma se le advierte al usuario que no todas las filas
 *        fueron seleccionadas y se le da la posibilidad de sleccionarlas
 */
export default {
  props: {
    selected: Array,  // filas seleccionadas
    items: Array,    // todo el array
    // si se envia en true utiliza la funcion @criterio definida en el padre cuando se hace click en seleccionar todas
    custom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    seleccion: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('update:selected', value)
      }
    },
    todas: {
      get () {
        return this.items
      },
      set (value) {
        this.$emit('update:items', value)
      }
    }
  },
  methods: {
    seleccionar () {
      if (this.custom) {
        this.$emit('criterio')
      } else {
        this.seleccion = [...this.todas]
      }
    }
  }
}
</script>