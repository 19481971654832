var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","persistent":_vm.load,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"word-break":"normal"}},[_vm._v(" Abrir Caja "),(_vm.local_nombre != '')?_c('div',{staticClass:"body-1 mt-1 ml-sm-3 ml-0"},[_vm._v(" "+_vm._s(_vm.local_nombre)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('FechaPicker',{attrs:{"label":"Fecha","hide-details":"","disabled":""},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('v-text-field-money',{attrs:{"label":"Transporte","properties":{
              prefix: '$',
              type: 'tel',
              'hide-details': true,
              outlined: true,
              readonly: true,
              filled: true,
              dense: true
            },"options":{
              locale: 'es-AR',
              precision: 2,
              empty: null
            }},model:{value:(_vm.transporte),callback:function ($$v) {_vm.transporte=$$v},expression:"transporte"}})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end pb-4"},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"success","loading":_vm.load},on:{"click":function($event){return _vm.abrirCaja()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-cash-register")]),_vm._v(" Abrir Caja ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }